import React, { useState, createRef, useEffect } from 'react'
import styles from './youtube-video.module.scss'

const YoutubeVideo = ({url}) => {

    const [showVideo, setShowVideo] = useState(false);

    const container = createRef();
    
    useEffect(() => {

        const onVideoIntersection = (entries) => {
            if(!entries || entries.length <= 0){
                return;
            }
    
            if(entries[0].isIntersecting){
                setShowVideo(true);
                videoObserver.disconnect();
            }
        };

        const videoObserver = new IntersectionObserver(onVideoIntersection, {
            rootMargin: '100px 0px',
            threshold: 0.25
        });

        if( window && 'IntersectionObserver' in window){
            if( container && container.current){
                videoObserver.observe(container.current);
            }
        } else{
            setShowVideo(true);
        }
    }, [container]);

    return (
        <div className={styles.youtubeContainer} ref={container}>
            {
            showVideo ?
            <iframe 
                title="Reflect with Raksha | Latest Video" 
                width="560" 
                height="315" 
                src={url} 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen/> : undefined
            }
        </div>
    )
}

export default YoutubeVideo