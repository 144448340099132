import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/layout'
import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import {INLINES, BLOCKS} from '@contentful/rich-text-types'
import Signature from '../components/signature'
import SEO from '../components/seo'
import YoutubeVideo from '../components/youtube-video'


export const query = graphql`
  query($slug: String!){
    contentfulBlogPost(slug: {eq:$slug}){
      title
      datePublished(formatString: "MMMM Do, YYYY")
      postContent{ 
        json
      }
      excerpt
    }
  }
`

const Blog = (props) => {
    const options = {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: (node) => {
            const alt = node.data.target.fields.title['en-US']
            const url = node.data.target.fields.file['en-US'].url
            return <img alt={alt} src={url} />
          },
          [INLINES.HYPERLINK]: (node) => {
            if (node.data.uri.includes("youtube")){
              return <YoutubeVideo url={node.data.uri}/>
            }
            return <a href={node.data.uri}>{node.content[0].value}</a>
          }
        }
      }
        return (
          <Layout>        
              <SEO title={props.data.contentfulBlogPost.title} description={props.data.contentfulBlogPost.excerpt}/>      
              <h1>{props.data.contentfulBlogPost.title}</h1>
              <p>{props.data.contentfulBlogPost.datePublished}</p>
              {documentToReactComponents(props.data.contentfulBlogPost.postContent.json, options)}
              <Signature />
            </Layout>
        )
}

export default Blog