import React from 'react'
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import styles from './signature.module.scss'

const Signature = () => {
    const image = useStaticQuery(graphql`
    query{
      fileName: file(relativePath: { eq: "images/RakshaSignature.png" }) {
        childImageSharp{
          fluid(maxWidth: 260, maxHeight:120){
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    `)

    return (
      <div className={styles.container}>
        <Img fluid={image.fileName.childImageSharp.fluid} alt="" />
      </div>
    )
}

export default Signature;